import React from 'react';
import TweenOne from 'rc-tween-one';
import SvgDrawPlugin from 'rc-tween-one/lib/plugin/SvgDrawPlugin';

import PropTypes from 'prop-types';
import styles from './NameAnimation.module.css';

const propTypes = {
	text : PropTypes.string.isRequired
};

TweenOne.plugins.push(SvgDrawPlugin);

const NameAnimation = () => {
	return (
		<div className={styles.container}>
			<svg className={styles.svgContent} viewBox="0 0 100 75" xmlns="http://www.w3.org/2000/svg">
				<text>
					<tspan x="0" y="38" fontSize="28">
						Hey,
					</tspan>
					<tspan x="0" y="65" fontSize="18">
						I ‘m Xiuming
					</tspan>
				</text>
			</svg>
		</div>
	);
};

NameAnimation.propType = propTypes;
export default NameAnimation;
