import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NameAnimation from '../../../components/NameAnimation';

import SocialProfile from '../../../common/SocialProfile/SocialProfile';
// import SocialProfilesNoMargin from '../../../common/SocialProfile/SocialProfile';

// import NameAnimation from './NameAnimation';

import { BannerWrapper, BannerInner, Title } from './style';
import { SocialProfiles, SocialProfilesNoMargin } from '../../About/style';
import { IoLogoGithub, IoLogoInstagram, IoLogoTwitter, IoLogoLinkedin } from 'react-icons/io';

type BannerProps = {};

const Banner: React.FunctionComponent<BannerProps> = () => {
	const SocialLinks = [
		{
			icon: <IoLogoGithub />,
			url: 'https://www.github.com/xiumingxu/',
			tooltip: 'Github'
		},
		{
			icon: <IoLogoInstagram />,
			url: 'https://www.instagram.com/xixovo/',
			tooltip: 'Instagram'
		},

		{
			icon: <IoLogoLinkedin />,
			url: 'https://www.linkedin.com/in/xiumingxu/',
			tooltip: 'Linked In'
		}
	];

	return (
		<BannerWrapper>
			<BannerInner>
				<NameAnimation />
				<SocialProfilesNoMargin>
					<SocialProfile items={SocialLinks} />
				</SocialProfilesNoMargin>
			</BannerInner>
		</BannerWrapper>
	);
};

export default Banner;
